import { formatDate } from '@angular/common';
import * as moment from 'moment';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MONTH_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function formatDateDto(date: Date | null): string {
  return date ? formatDate(date, 'yyyy-MM-dd', 'en-US') : null;
}

/**
 *
 * @param date momentJS or Date object
 * @returns date string in format YYYY-MM-DD
 */
export function dateToStringWithoutTimezone(date: Date | moment.Moment): string {
  return date ? moment(date).utcOffset(0, true).format() : '';
}
