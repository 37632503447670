<h2 class="section-title">
  {{ title }}
</h2>

<div class="excel-form">
  <mat-form-field
    *ngIf="enabledControls.date"
    appearance="fill"
    color="accent"
    (click)="picker1.open()"
  >
    <mat-label>{{ 'ADMIN.DAYS_LEFT.SELECT_DATE' | translate }}</mat-label>
    <input
      [formControl]="dateControl"
      matInput
      [matDatepicker]="picker1"
      [max]="maxDate"
      [min]="minDate"
    />
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker
      #picker1
      color="primary"
    ></mat-datepicker>
  </mat-form-field>

  <app-month-field
    *ngIf="enabledControls.month"
    [formControl]="monthControl"
    [maxDate]="maxDateStartOfMonth"
    [minDate]="minDate"
    [label]="'ADMIN.CLIENT_HOURS.MONTH' | translate"
  ></app-month-field>

  <mat-form-field *ngIf="enabledControls.project" appearance="fill" subscriptSizing="dynamic">
    <mat-label>{{ 'ADMIN.CLIENT_HOURS.PROJECTS' | translate }}</mat-label>
    <input
      type="text"
      matInput
      placeholder="{{ 'ADMIN.CLIENT_HOURS.PROJECTS' | translate }}"
      #filter
      [formControl]="projectControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayProjectNameFn"
    >
      <mat-option
        *ngFor="let project of projectList | filter: filter.value : 'name'"
        [value]="project"
      >
        {{ project.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <app-employee-search
    *ngIf="enabledControls.employee"
    (clear)="onEmployeeSelect(null)"
    (onselect)="onEmployeeSelect($event)"
  ></app-employee-search>

  <button
    (click)="onSubmit()"
    mat-raised-button
    color="primary"
    type="button"
    class="excel-button"
    [disabled]="isSubmitDisabled"
  >
    <mat-icon>download</mat-icon>
    {{ 'ADMIN.CLIENT_HOURS.EXPORT' | translate }}
  </button>
</div>
