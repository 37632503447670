<div class="login-form align-center" *ngIf="!isUserLogIn">
  <div class="logo">
    <img src="../../assets/img/logo.png" alt="Dreamix Logo" />
  </div>

  <asl-google-signin-button
    size="large"
    text="signin"
    theme="filled_black"
    type="standard"
    width="280"
  ></asl-google-signin-button>

  <div class="password-signin">
    <button *ngIf="showPasswordLoginLink" mat-button type="button" (click)="onPasswordLogin()">
      {{ 'LOGIN.PASSWORD_SIGN_IN' | translate }}
    </button>
    <form *ngIf="showPasswordLogin" [formGroup]="formLogin" (ngSubmit)="submitPasswordLogin()">
      <mat-form-field color="accent" appearance="fill">
        <input
          matInput
          formControlName="email"
          [placeholder]="'LOGIN.ENTER_EMAIL' | translate"
          type="text"
        />
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          formControlName="password"
          [placeholder]="'LOGIN.ENTER_PASSWORD' | translate"
          [type]="showPassword ? 'text' : 'password'"
        />
        <mat-icon matSuffix (click)="showPassword = !showPassword">
          {{ showPassword ? 'visibility' : 'visibility_off' }}
        </mat-icon>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit">
        {{ 'LOGIN.SIGN_IN' | translate }}
      </button>
    </form>

  </div>
  <div class="password-signin" >
    <button *ngIf="showChangePasswordLink" mat-button type="button" (click)="onChangePassword()">
      {{ 'LOGIN.CHANGE_PASSWORD' | translate }}
    </button>
    <form *ngIf="showChangePassword" [formGroup]="formChangePassword" (ngSubmit)="submitChangePassword()">
      <mat-form-field color="accent" appearance="fill">
        <input
          matInput
          formControlName="email"
          [placeholder]="'LOGIN.ENTER_EMAIL' | translate"
          type="text"
        />
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit">
        {{ 'LOGIN.CHANGE_PASSWORD' | translate }}
      </button>
      <div class="margin-24-top">
        <a (click)="onPasswordLogin()" class="back-to-login-form"><< {{ 'LOGIN.BACK_TO_SIGN_IN_FORM' | translate }}</a>
      </div>
    </form>

  </div>
</div>
<mat-spinner *ngIf="isUserLogIn"></mat-spinner>
