import {
  Component,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { vacationIcons } from './calendar.config';
import {
  EmployeeService,
  HolidaysService,
  LoginService,
  ProjectService,
} from 'src/app/shared/services';
import { CalendarModel, EmployeeProject, IHoliday, IProject } from 'src/app/shared/models';
import { DaysLoggedTime, Request } from 'src/app/shared/models/request-calendar.model';
import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';
import { PersonalCalendarService } from 'src/app/shared/services/personal-calendar.service';
import { MatPaginator } from '@angular/material/paginator';
import { ObservablesService } from 'src/app/shared/services';
import { MatSelectChange } from '@angular/material/select';
import {
  CalendarHelper,
  DayDetails,
} from 'src/app/shared/services/helpers/calendar-helper.service';
import { FormControl } from '@angular/forms';
import { TimeLogController } from 'src/app/shared/services/time-log.controller';
import { MatDialog } from '@angular/material/dialog';
import { TimeLogTableDialogComponent } from 'src/app/shared/dialogs/time-log-table-dialog/time-log-table-dialog.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnDestroy, AfterViewInit {
  @Input() projectId: number;
  @Input() isCalendarHome = true;
  @ViewChild('calendarHeader') calendarHeader: CalendarHeaderComponent;
  @ViewChild('paginator') paginator: MatPaginator;

  public vacationIcons = vacationIcons;
  public calendar: CalendarModel[] = [];
  public allHolidays: Array<IHoliday>;
  public isLocalLoading: boolean = true;
  public project: IProject;
  public filter: string = '';
  public pageSize = 20;
  public currentPage = 0;
  public currentPageData: CalendarModel[];
  public pageSizeOptions = [20, 40, 60, 80];
  public daysDetails: DayDetails[];
  public projectsControl: FormControl = new FormControl();
  public projectList: EmployeeProject[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private loginService: LoginService,
    private calendarService: PersonalCalendarService,
    private holidayService: HolidaysService,
    private changeDetection: ChangeDetectorRef,
    private projectService: ProjectService,
    private observablesService: ObservablesService,
    private calendarHelper: CalendarHelper,
    private employeeService: EmployeeService,
    private ctrl: TimeLogController,
    public dialog: MatDialog,
  ) {}

  ngAfterViewInit(): void {
    this.loadData();
    this.observablesService.updateCalendar
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.loadData());
    this.changeDetection.detectChanges();

    const savedPageSize = parseInt(localStorage.getItem('pageSize'));
    if (savedPageSize) {
      this.pageSize = +savedPageSize;
    }
  }

  private loadData(): void {
    this.updateDays();
    if (this.isCalendarHome) {
      this.getCurrentMonthRequests();
    } else {
      this.initTimeLoggingPage();
    }

    if (this.projectId) {
      this.projectService
        .getProjectByIdClientUnauthorized(this.projectId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => (this.project = data));
    }
  }

  private mapRequestsToDays = (requests: Request[]) => {
    const days: CalendarModel['days'] = {};
    if (!requests.length) return days;

    requests.forEach((request) => {
      const currentDate = moment(request.fromDate);
      const lastDate = moment(request.toDate);
      let pattern = request.pattern;

      while (lastDate.diff(currentDate, 'days') + 1 > 0) {
        const monthDay = currentDate.date();

        days[monthDay] = {
          firstHalf: days[monthDay]?.firstHalf || pattern[0] === '1',
          secondHalf: days[monthDay]?.secondHalf || pattern[0] === '2',
          vacationType: request.vacationType,
          subType: request.subType,
        };

        pattern = pattern.substring(1);
        currentDate.add(1, 'days');
      }
    });

    return days;
  };

  private mapLoggedTimeToDays = (daysLoggedTime: DaysLoggedTime[]) => {
    const days: CalendarModel['daysLoggedTime'] = {};
    if (!daysLoggedTime.length) return days;

    return daysLoggedTime.reduce((days, day) => {
      const monthDay = moment(day.date).date();

      days[monthDay] = {
        ...day,
        hours: days[monthDay] ? days[monthDay].hours + day.hours : day.hours,
      };

      return days;
    }, {});
  };

  private updateDays(): void {
    const [firstMonthDate, lastMonthDate] = this.calendarHeader.getMonthDates();
    this.calendarHeader.currentMonthTitle = firstMonthDate.format('MMMM YYYY');

    const holidays = this.holidayService.getAllHolidaysByYear(firstMonthDate.year());
    holidays.pipe(takeUntil(this.destroy$)).subscribe((responseHolidays) => {
      this.allHolidays = responseHolidays;
      this.daysDetails = this.calendarHelper.getMonthDays(lastMonthDate);
    });
  }

  private updatePageData() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = Math.min(startIndex + this.pageSize, this.calendar.length);

    this.currentPageData = this.calendar.slice(startIndex, endIndex);
  }

  private async initTimeLoggingPage(): Promise<void> {
    this.projectList = await this.employeeService.loadMyProjects(this.loginService.getUser().id);
    this.projectList.sort((a, b) =>
      a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase() ? 1 : -1,
    );
    this.handleLoading(false);
  }

  getCurrentMonthRequests = () => {
    const [firstMonthDate] = this.calendarHeader.getMonthDates();

    this.calendarService
      .getAllRequestsInCheckedCalendars(
        firstMonthDate.format('YYYY-MM'),
        this.loginService.getUserId(),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.calendar = data.map((emp) => ({
          id: emp.employeeId,
          fullName: emp.fullName,
          email: emp.fullName,
          workDays: emp.workDays,
          days: this.mapRequestsToDays(emp.requests),
          totalMonthWorkingHours: emp.totalMonthWorkingHours,
          totalWorkingHours: emp.totalWorkingHours,
        }));
        this.updateDays();
        this.handleLoading(false);

        if (this.isCalendarHome) {
          this.applyFilter(this.filter);
        } else {
          this.getCurrentMonthTimeLogging(this.projectsControl.value.id);
        }
      });
  };

  getCurrentMonthTimeLogging = (projectId: number) => {
    const [firstMonthDate, lastDateCurrentMonth] = this.calendarHeader.getMonthDates();
    this.calendarService
      .getTimeLogging(
        projectId,
        firstMonthDate.format('YYYY-MM-DD'),
        lastDateCurrentMonth.format('YYYY-MM-DD'),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.calendar = data.map((emp) => ({
          id: emp.employeeId,
          fullName: emp.fullName,
          email: emp.fullName,
          workDays: emp.workDays,
          days: this.mapRequestsToDays(emp.requests),
          daysLoggedTime: this.mapLoggedTimeToDays(emp.loggedTimesPerProjectPerEmployee),
          totalMonthWorkingHours: emp.totalMonthWorkingHours,
          totalWorkingHours: emp.totalWorkingHours,
        }));

        this.updateDays();
        this.applyFilter(this.filter);
        this.handleLoading(false);
      });
  };

  resetCalendar = () => {
    this.paginator.firstPage();
    this.applyFilter('');
    this.getCurrentMonthRequests();
  };

  onPageChange(event) {
    this.currentPage = event.pageIndex;
    this.updatePageData();
  }

  onPageSizeChange(event: MatSelectChange): void {
    this.pageSize = event.value;
    localStorage.setItem('pageSize', this.pageSize.toString());
    this.paginator.pageSize = this.pageSize;
    this.paginator.firstPage();
    this.onPageChange({ pageIndex: 0, pageSize: this.pageSize });
  }

  applyFilter(event: string) {
    this.filter = event;
    let filterEmployee: CalendarModel[] = [];

    if (this.filter) {
      filterEmployee = [
        ...this.calendar.filter((calendar) =>
          calendar.fullName.toLowerCase().includes(this.filter.trim().toLowerCase()),
        ),
      ];
      this.currentPageData = [...filterEmployee];
    } else {
      this.updatePageData();
    }
  }

  showVacationIcon = (day: DayDetails, emp: CalendarModel) => {
    const vacationType = emp.days[day.date]?.vacationType;
    if (!vacationType) return false;

    if (emp.days[day.date - 1]?.vacationType === vacationType) return false;
    return true;
  };

  handleLoading = (val: boolean) => {
    this.isLocalLoading = val;
  };

  async onProjectChange(event: MatAutocompleteSelectedEvent) {
    const { id } = event.option.value;
    await this.ctrl.changeProject(id);
    this.getCurrentMonthTimeLogging(id);
  }

  expandEmployee(empId: number) {
    const [firstMonthDate, lastDateCurrentMonth] = this.calendarHeader.getMonthDates();

    this.ctrl
      .handleSelect(empId, this.projectsControl.value.id, firstMonthDate, lastDateCurrentMonth)
      .then(() => {
        this.dialog.open(TimeLogTableDialogComponent, {
          data: this.ctrl.data.value,
        });
      });
  }

  getStripeStyles(day, emp, useCase) {
    if (this.isCalendarHome) {
      return false;
    }

    const loggedTime = !day.isWeekend && emp.daysLoggedTime && emp.daysLoggedTime[day.date];
    const loggedHoursPercentage =
      emp.daysLoggedTime && (emp.daysLoggedTime[day.date]?.hours * 100) / 8;
    const vacationFirstHalf = emp.days[day.date]?.firstHalf;
    const vacationSecondHalf = emp.days[day.date]?.secondHalf;
    const vacationType = emp.days[day.date]?.vacationType;

    switch (useCase) {
      case 'STRIPE_GREEN':
        return loggedTime;
      case 'STRIPE_GREEN_WIDTH':
        return loggedTime?.hours ? loggedHoursPercentage : 100;
      case 'STRIPE_GREEN_LEFT':
        return vacationFirstHalf && !vacationSecondHalf ? 50 : 0;
      case 'STRIPE_RED':
        return !day.isWeekend;
      case 'STRIPE_RED_WIDTH': {
        if (vacationType && !vacationFirstHalf && !vacationSecondHalf) {
          return 0;
        } else if (vacationFirstHalf || vacationSecondHalf) {
          if (loggedTime?.hours) {
            return 50 - loggedHoursPercentage;
          } else {
            return 50;
          }
        } else {
          if (loggedTime?.hours) {
            return 100 - loggedHoursPercentage;
          } else {
            return 100;
          }
        }
      }
      case 'STRIPE_RED_LEFT':
        if (loggedTime?.hours) {
          if (vacationFirstHalf) {
            return 50 + loggedHoursPercentage;
          } else {
            return loggedHoursPercentage;
          }
        } else {
          if (vacationFirstHalf) {
            return 50;
          }
        }
        return 0;
    }
  }

  displayFn(project: EmployeeProject): string {
    return project ? `${project.name}` : null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
