import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// import { MatDatepicker } from '@angular/material/datepicker';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { EmployeeSearchComponent } from 'src/app/shared/components/employee-search/employee-search.component';
import { MonthFieldComponent } from "src/app/shared/components/month-field/month-field.component";
import { EmployeeProject, IEmployee } from 'src/app/shared/models';
import { AlertService, AlertType, EmployeeService, LoginService } from 'src/app/shared/services';
import { DATE_FORMATS } from 'src/app/shared/services/helpers/date.helper';
import { MaterialModule } from 'src/app/shared/material.module';
import { SharedModule } from 'src/app/shared/shared.module';

interface SubmitValue {
  fromDate: string;
  employeeId?: number;
  project?: EmployeeProject;
  toDate: string;
}

@Component({
  imports: [
    CommonModule,
    EmployeeSearchComponent,
    MaterialModule,
    MonthFieldComponent,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
  selector: 'app-report-export',
  standalone: true,
  styleUrls: ['./report-export.component.scss'],
  templateUrl: './report-export.component.html',
})
export class ReportExportComponent implements OnInit {
  private firstDateOfTheMonth = moment().add(-1, 'month').startOf('month');

  employeeList: IEmployee[] = [];
  minDate = moment().add(-12, 'months').startOf('month');
  maxDate = moment();
  maxDateStartOfMonth = moment().startOf('month');
  projectList: EmployeeProject[] = [];

  // controls
  dateControl = new FormControl(moment());
  employeeControl: FormControl = new FormControl();
  monthControl = new FormControl(this.firstDateOfTheMonth);
  projectControl: FormControl = new FormControl();

  @Input() enabledControls = {
    date: false,
    employee: false,
    month: false,
    project: false,
  };
  @Input() title: string;

  @Output() emitSubmit = new EventEmitter<SubmitValue>();
  @Output() employeeChange = new EventEmitter<SubmitValue>();

  get isDateControlValid(): boolean {
    return !this.enabledControls.date || this.dateControl.valid;
  }

  get isMonthControlValid(): boolean {
    return !this.enabledControls.month || this.monthControl.valid;
  }

  get isProjectControlValid(): boolean {
    return !this.enabledControls.project || this.projectControl.valid;
  }

  get isSubmitDisabled(): boolean {
    return (
      (this.enabledControls.date && !this.dateControl.valid) ||
      (this.enabledControls.month && !this.monthControl.valid) ||
      (this.enabledControls.project && !this.projectControl.value)
    );
  }

  constructor(
    private alertService: AlertService,
    private employeeService: EmployeeService,
    public loginService: LoginService,
    public translate: TranslateService,
  ) {}

  displayProjectNameFn(project: EmployeeProject): string {
    return project ? `${project.name}` : null;
  }

  async initProjects() {
    this.projectList = await this.employeeService.loadMyProjects(this.loginService.getUser().id);
    this.projectList.sort((a, b) =>
      a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase() ? 1 : -1,
    );
    if (this.projectList.length === 1) {
      this.projectControl.setValue(this.projectList[0]);
    }
  }

  ngOnInit() {
    this.initProjects();
  }

  onEmployeeSelect(employeeId: number) {
    this.employeeControl.setValue(employeeId);
    if (employeeId) this.employeeChange.emit(this.generateReturnObject());
  }

  onSubmit() {
    if (this.validateInput()) {
      this.emitSubmit.emit(this.generateReturnObject());
    }
  }

  private generateReturnObject(): SubmitValue {
    return {
      fromDate: this.enabledControls.date
        ? this.dateControl.value.format('YYYY-MM-DD')
        : this.monthControl.value.startOf('month').format('YYYY-MM-DD'),
      employeeId: this.employeeControl.value,
      project: this.projectControl.value,
      toDate: this.monthControl.value.endOf('month').format('YYYY-MM-DD'),
    };
  }

  private validateInput(): boolean {
    if (!this.isDateControlValid || !this.isMonthControlValid || !this.isProjectControlValid) {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.CLIENT_HOURS.FILL_DATE_AND_PROJECT').toString(),
        AlertType.error,
      );
      return false;
    }
    return true;
  }
}
