import { ClipboardModule } from '@angular/cdk/clipboard';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TableWrapperComponent } from './components/table-wrapper/table-wrapper.component';
import { MaterialModule } from './material.module';
import { DialogComponent } from './dialogs/dialog/dialog.component';
import { ImageDialogComponent } from './dialogs/image-dialog/image-dialog.component';
import { ImageCropDialogComponent } from './dialogs/image-crop-dialog/image-crop-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EditorComponent } from './components/editor/editor.component';
import { MockEditorComponent } from './components/editor/mock-editor.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/TokenInterceptor';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './custom.date.adapter';
import { TranslateModule } from '@ngx-translate/core';
import { EmployeeService } from './services/employee.service';
import { LoginService } from './services/login.service';
import { ImageListDialogComponent } from './dialogs/image-list-dialog/image-list-dialog.component';
import { ReportErrorListComponent } from './components/report-error-list/report-error-list.component';
import { ReportFeedbackDialogComponent } from './dialogs/report-feedback-dialog/report-feedback-dialog.component';
import { TimeLogTableComponent } from './components/time-log-table/time-log-table.component';
import { TimeLogInputComponent } from './components/time-log-input/time-log-input.component';
import { FileUploadButtonComponent } from './components/file-upload-button/file-upload-button.component';
import { WithLoadingComponent } from './components/with-loading/with-loading.component';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { ReimbursementRequestsTableComponent } from './components/reimbursement-requests-table/reimbursement-requests-table.component';
import { CalendarDirective } from './components/calendar-directive/calendar.directive';
import { TravelRequestsTableComponent } from './components/travel-requests-table/travel-requests-table.component';
import { SecurePipe } from './pipes/secure.pipe';
import { TravelRequestsComponent } from './components/travel-requests/travel-requests.component';
import { AddDestinationFormComponent } from './components/travel-requests/add-destination-form/add-destination-form.component';
import { EditTravelRequestDialogComponent } from './dialogs/edit-travel-request-dialog/edit-travel-request-dialog.component';
import { CalendarAddProjectDialogComponent } from './dialogs/calendar-add-project-dialog/calendar-add-project-dialog.component';
import { CalendarCreateDialogComponent } from './dialogs/calendar-create-dialog/calendar-create-dialog.component';
import { FilterPipe } from './pipes/filter.pipe';
import { CalendarComponent } from '../vacation/calendar/calendar.component';
import { CalendarHeaderComponent } from '../vacation/calendar/calendar-header/calendar-header.component';
import { CalendarDialogComponent } from '../vacation/calendar/calendar-dialog/calendar-dialog.component';
import { ServiceModule } from './services/services.module';
import { TimeLogTableDialogComponent } from './dialogs/time-log-table-dialog/time-log-table-dialog.component';
import { AutocompletePersonComponent } from 'src/app/shared/components/autocomplete-person/autocomplete-person.component';
import { HumanizeDatePipe } from 'src/app/shared/pipes/humanize-date.pipe';
import { ImageUploadBase64Component } from 'src/app/shared/components/image-upload-base64/image-upload-base64.component';
import { Base64ToImagePipe } from 'src/app/shared/pipes/base64-to-image.pipe';
import { WidgetDashboardDialogComponent } from './dialogs/widget-dashboard-dialog/widget-dashboard-dialog.component';
import { FormatBytesPipe } from 'src/app/shared/pipes/format-bytes.pipe';
import { WeeklyNuggetComponent } from 'src/app/shared/components/weekly-nugget/weekly-nugget.component';
import { MySafeHtml } from 'src/app/admin/shared/pipes/safe-html.pipe';
import { NuggetContentDirective } from 'src/app/shared/directives/nugget-content.directive';
import { SpinnerOverlayComponent } from 'src/app/shared/components/spinner-overlay/spinner-overlay.component';
import { EmployeeTableComponent } from './components/employee-table/employee-table.component';
import { EmployeeFiltersDialogComponent } from './components/employee-filters-dialog/employee-filters-dialog.component';
import { MultiSelectFieldComponent } from './components/multi-select-field/multi-select-field.component';
import { RestOfListPipe } from './pipes/rest-of-list.pipe';

export function currentUserLoader(
  employeeService: EmployeeService,
  loginService: LoginService,
): () => Promise<any> {
  return () =>
    new Promise((resolve) => {
      if (loginService.isUserLogIn()) {
        employeeService.getEmployeeById(loginService.getUserId()).subscribe((employee) => {
          loginService.setUser(employee);
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
}

@NgModule({
  imports: [
    ClipboardModule,
    CommonModule,
    MaterialModule,
    ImageCropperModule,
    ReactiveFormsModule,
    EditorModule,
    FormsModule,
    TranslateModule,
    WithLoadingPipe,
    RouterModule,
    ServiceModule.forRoot(),
  ],
  declarations: [
    TableWrapperComponent,
    DialogComponent,
    ImageDialogComponent,
    ImageCropDialogComponent,
    EditorComponent,
    MockEditorComponent,
    ImageListDialogComponent,
    ReportErrorListComponent,
    ReportFeedbackDialogComponent,
    TimeLogTableComponent,
    TimeLogInputComponent,
    FileUploadButtonComponent,
    WithLoadingComponent,
    ReimbursementRequestsTableComponent,
    TravelRequestsTableComponent,
    SecurePipe,
    FilterPipe,
    TravelRequestsComponent,
    AddDestinationFormComponent,
    EditTravelRequestDialogComponent,
    CalendarDirective,
    CalendarAddProjectDialogComponent,
    CalendarCreateDialogComponent,
    CalendarComponent,
    CalendarHeaderComponent,
    CalendarDialogComponent,
    TimeLogTableDialogComponent,
    AutocompletePersonComponent,
    HumanizeDatePipe,
    ImageUploadBase64Component,
    Base64ToImagePipe,
    WidgetDashboardDialogComponent,
    FormatBytesPipe,
    WeeklyNuggetComponent,
    MySafeHtml,
    NuggetContentDirective,
    SpinnerOverlayComponent,
    EmployeeFiltersDialogComponent,
    EmployeeTableComponent,
    MultiSelectFieldComponent,
    RestOfListPipe,
  ],
  exports: [
    CommonModule,
    TableWrapperComponent,
    ImageCropDialogComponent,
    EditorComponent,
    ImageListDialogComponent,
    TimeLogTableComponent,
    TimeLogInputComponent,
    FileUploadButtonComponent,
    WithLoadingComponent,
    ReimbursementRequestsTableComponent,
    TravelRequestsTableComponent,
    TravelRequestsComponent,
    AddDestinationFormComponent,
    EditTravelRequestDialogComponent,
    CalendarDirective,
    SecurePipe,
    FilterPipe,
    CalendarAddProjectDialogComponent,
    CalendarCreateDialogComponent,
    CalendarComponent,
    CalendarHeaderComponent,
    CalendarDialogComponent,
    AutocompletePersonComponent,
    HumanizeDatePipe,
    ImageUploadBase64Component,
    Base64ToImagePipe,
    FormatBytesPipe,
    WeeklyNuggetComponent,
    MySafeHtml,
    NuggetContentDirective,
    SpinnerOverlayComponent,
    EmployeeFiltersDialogComponent,
    EmployeeTableComponent,
    MultiSelectFieldComponent,
    RestOfListPipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        DatePipe,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true,
        },
        { provide: DateAdapter, useClass: CustomDateAdapter },
        {
          provide: APP_INITIALIZER,
          useFactory: currentUserLoader,
          deps: [EmployeeService, LoginService],
          multi: true,
        },
      ],
    };
  }
}
