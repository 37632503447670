import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { IPosition } from '../models/position.model';

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  private urlBase: string = 'api/positions';
  private positions$: Observable<IPosition[]>;

  constructor(private httpClient: HttpClient) {}

  getAllPositions(): Observable<IPosition[]> {
    this.positions$ = this.positions$ ?? this.fetchAllPositions().pipe(shareReplay());
    return this.positions$;
  }

  private fetchAllPositions(): Observable<IPosition[]> {
    return this.httpClient.get<IPosition[]>(this.urlBase);
  }
}
