import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';

import { MONTH_FORMATS } from 'src/app/shared/services/helpers/date.helper';
import { MaterialModule } from 'src/app/shared/material.module';

@Component({
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthFieldComponent),
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS },
  ],
  selector: 'app-month-field',
  standalone: true,
  styleUrls: ['./month-field.component.scss'],
  templateUrl: './month-field.component.html',
})
export class MonthFieldComponent implements ControlValueAccessor {
  private _value: moment.Moment;

  control = new FormControl(moment());
  onChange = (_: moment.Moment) => {};
  onTouched = () => {};

  @Input() maxDate: string;
  @Input() minDate: string;
  @Input() label: string;
  @Input() placeholder: string;

  @Input()
  set value(input: moment.Moment) {
    this._value = input;
    this.onChange(input);
    this.onTouched();
  }
  get value(): moment.Moment {
    return this._value;
  }

  constructor() {}

  onMonthSelected(input: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    this.value = input;
    datepicker.close();
  }

  // ControlValueAccessor methods
  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(value: moment.Moment) {
    this._value = value;
  }
}
